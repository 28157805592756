<template>
  <div>
    <el-form style="width: 100%">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请输入学生姓名">
            <el-input placeholder="请输入学生姓名" v-model="searchData.name" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入学生手机号码">
            <el-input placeholder="请输入手机号" v-model="searchData.phone" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入学生身份证号码">
            <el-input
              placeholder="请输入学生身份证号码"
              v-model="searchData.id_card"
            />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="院系名称">
            <el-select v-model="searchData.domain_id" placeholder="请选择院系">
              <el-option
                v-for="item in professionalDropDown"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="所属专业" prop="domain_id_child">
            <el-select
              v-model="searchData.domain_id_child"
              placeholder="请选择专业"
              @focus="onTheirProfession(searchData.domain_id)"
            >
              <el-option
                v-for="item in theirProfession"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="层次" prop="name">
            <el-select
              v-model="searchData.length"
              placeholder="请选择层次"
              @focus="onLayerPullDown()"
            >
              <el-option
                v-for="item in hierarchicalData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="选择报名时间">
            <el-date-picker
              v-model="startEnd"
              type="daterange"
              start-placeholder="开始时间"
              range-separator="到"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item>
            <el-button type="primary" @click="searchBtn"> 搜索 </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格数据 -->

    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column prop="number" fixed label="报名序号" width="120" />
      <el-table-column prop="name" fixed label="报名姓名" width="100" />
      <el-table-column prop="sex" label="性别" width="100" />
      <el-table-column prop="nation" label="民族" width="100" />
      <el-table-column prop="education" label="学历" width="100" />
      <el-table-column prop="id_card" label="身份证" width="180" />
      <el-table-column prop="phone" label="手机号" width="150" />
      <el-table-column prop="college_name" label="院系" width="180" />
      <el-table-column prop="domain_name" label="专业" width="180" />
      <el-table-column prop="level_name" label="学制" width="100" />
      <el-table-column prop="addr" label="地址" width="180" />
      <el-table-column prop="parent_name" label="家长姓名" width="100" />
      <el-table-column prop="parent_phone" label="家长手机号" width="150" />
      <el-table-column prop="grades" label="中考成绩" width="100" />
      <el-table-column prop="graduated_school" label="毕业院校" width="180" />
<el-table-column prop="graduation_class" label="毕业班级" width="100" />
      <el-table-column
        prop="graduation_address"
        label="毕业学校所在县区"
        width="150"
      />
      <el-table-column
        prop="graduating_class_teacher"
        label="毕业班主任"
        width="100"
      />
      <el-table-column prop="manager" label="经办人" width="80" />
      <el-table-column prop="manager_dept" label="经办部门" width="100" />
      <el-table-column prop="create_time" label="填写时间" width="180" />
      <el-table-column label="处理状态" width="100">
        <template v-slot="{ row }">
          {{ processingStatusEcho[row.status] }}
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="处理备注" width="100" />
      <el-table-column label="操作" align="center" fixed="right" width="200">
        <template #default="{ row }">
          <el-button
            v-permission="['signUpDealWith']"
            type="primary"
            size="small"
            @click="onEditBtn(row)"
          >
            处理</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchData.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchData.total"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { toBeReviewedAPI } from '@/api/reviewed'
import {
  listOfSelectionOfHospitalsAPI,
  professionalSelectionListAPI,
  listOfHierarchicalListingAPI
} from '@/api/specialized'
import { ElMessage } from 'element-plus'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
const searchData = ref({
  page: 1, // 当前页
  total: 0, // 总数
  limit: 10, //  每页几条数据
  phone: '', //  手机号
  name: '', //  学生姓名
  id_card: '', // 身份证
  start_time: '', // 开始时间
  end_time: '', // 结束时间
  domain_id: '',
  length: ''
})
const startEnd = ref([new Date(''), new Date('')])
const searchBtn = () => {
  searchData.value.start_time = startEnd.value[0]
  searchData.value.end_time = startEnd.value[1]
  onToBeReviewed()
}

// 院系
const professionalDropDown = ref([])
const onListOfSelectionOfHospitals = async () => {
  const data = await listOfSelectionOfHospitalsAPI()

  professionalDropDown.value = data
}
onListOfSelectionOfHospitals()

// 专业
// 所属专业
const theirProfession = ref([])
const onTheirProfession = async (id) => {
  if (id) {
    const data = await professionalSelectionListAPI({
      college_id: id
    })
    theirProfession.value = data
  } else {
    ElMessage.error('请先选择院系')
  }
}

// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}

const processingStatusEcho = {
  0: '待审核',
  1: '通过',
  2: '不通过'
}

/** 表单 E */
/** 表格 S */
// 表格
const tableData = ref([])
const onToBeReviewed = async () => {
  const { data, total } = await toBeReviewedAPI(searchData.value)
  tableData.value = data
  searchData.value.total = total
}
onToBeReviewed()
const router = useRouter()
const onEditBtn = ({ id }) => {
  router.push(`/signUp/dealWith/${id}`)
}
/** 表格 E */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  searchData.value.limit = val
  onToBeReviewed()
}

// 页码值
const handleCurrentChange = (val) => {
  searchData.value.page = val
  onToBeReviewed()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped></style>
